import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'depot-putaway-help',
  template: `
    <h1>Put Away Help</h1>

    <h3>Capturing an image</h3>
    <p>Pressing F10, double clicking on the camera image, and clicking the "Capture Image"
    button <button type="button" mat-mini-fab color="accent"><span mat-icon-button class="fa fa-camera"></span></button>
    will all save an image to the current row</p>
    <p>
      Images with a red boarder are viewable on the external ordering site, images with the blue boarder are not.
      If an image has the "edit" <span class="fa fa-edit"></span> and "delete" <span class="fa fa-trash"></span>
      icons it has been taken as part of that row.
    </p>
  `
})
export class PutawayHelpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
