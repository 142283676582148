import { Component, DestroyRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';

import { AuthService, CancelRequestService, HelperService, SettingsService } from '@depot/@common';
import { IPutAwaySearch, PutawayRepositoryService } from '@depot/@data';
import { PutAwayGridDataSource } from '@depot/putaway';

import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'depot-putaway-grid',
  templateUrl: './putaway-grid.component.html',
  styleUrls: ['./putaway-grid.component.scss']
})
export class PutawayGridComponent implements OnInit, OnDestroy {
  public dataSource = new PutAwayGridDataSource(this.putawayRepo);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatExpansionPanel) searchPanel: MatExpansionPanel;
  public cachedFilters$ = new BehaviorSubject<IPutAwaySearch>({});
  public hasFilters$ = new BehaviorSubject<boolean>(false);
  get defaultFilters(): IPutAwaySearch {
    return {
      startDate: new Date(),
      endDate: null,
      userName: this.authService.user.userName,
      hasDownloaded: '',
      manualSearch: false,
      location: '',
    };
  }

  constructor(
    private putawayRepo: PutawayRepositoryService,
    private helper: HelperService,
    private settingsService: SettingsService,
    public activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private httpCancelService: CancelRequestService,
    private destroyRef: DestroyRef,
  ) {
    this.dataSource.isLoading$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(x => this.helper.IsGlobalSpinner$.set(x)); this.helper.IsGlobalBar$.set(true);

  }

  ngOnInit() {
    this.dataSource.totalRows$.subscribe(rows => this.paginator.length = rows);

    this.settingsService.dashboardSettings<IPutAwaySearch>(this.dataSource.filterName).then(cachedData => {

      if (!cachedData || cachedData.manualSearch === false) {
        cachedData = this.defaultFilters;
      }
      if (cachedData.manualSearch === true) {
        this.searchPanel.open();
      }
      cachedData.pageIndex = this.paginator.pageIndex;
      cachedData.takeCount = cachedData.takeCount > 0 ? cachedData.takeCount : this.paginator.pageSizeOptions[0];
      this.paginator.pageSize = cachedData.takeCount;
      this.paginator.pageIndex = cachedData.pageIndex;

      this.cachedFilters$.next(cachedData);
      this.loadData(null);
    });
  }

  public async loadData(e?: Event | null) {
    this.httpCancelService.cancelPendingRequests();
    if (e) {
      e.stopPropagation();
    }
    let sortInput = '';
    if (this.sort.active && this.sort.direction) {
      sortInput = this.sort.active + ' ' + this.sort.direction;
    }

    const values = this.cachedFilters$.getValue();
    values.takeCount = this.paginator.pageSize;
    values.pageIndex = this.paginator.pageIndex;
    values.orderBy = sortInput;
    if (e != null) {
      values.manualSearch = !!e;
    }
    const cachedData = await this.settingsService.dashboardSettings<IPutAwaySearch>(this.dataSource.filterName, values);

    this.dataSource.loadData(cachedData);
    this.helper.IsGlobalBar$.set(false);

  }

  public resetFilters(e?: Event) {
    if (e) {
      e.stopPropagation();
    }
    this.cachedFilters$.next(this.defaultFilters);
    this.paginator.firstPage();
    this.loadData();
  }

  public sortFilters() {
    this.paginator.firstPage();
    this.loadData();

  }

  ngOnDestroy(): void {

    this.cachedFilters$.complete();
    // this.depotContext.close();
  }

}
