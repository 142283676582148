import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup, UntypedFormArray } from '@angular/forms';

import { DepotValidators } from '@depot/@common';

import { BehaviorSubject } from 'rxjs';

type WarningFormGroup = FormGroup & {
  warnings: string[];
};

@Component({
  selector: 'app-putaway-edit-row',
  templateUrl: './putaway-edit-row.component.html',
  styleUrls: ['./putaway-edit-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class PutawayEditRowComponent implements OnInit {

  @Input() public row: WarningFormGroup;
  @Input() public form: FormGroup<{ rows: FormArray }>;
  @Input('index') public i: number;
  @Input() public lastFocusRow$: BehaviorSubject<number>;
  @Output() public partChange = new EventEmitter();
  @Output() public rowBlur = new EventEmitter();
  @Output() public removeFormItem = new EventEmitter<{ index: number; key: string }>();
  constructor() { }

  ngOnInit() {

  }

  // removeFormItem(index, id) {

  // }

  // onPartChange(row, index) {

  // }

  public onRowFocus(event: FocusEvent, index: number) {
    const element = (event.target as HTMLInputElement);
    element.setSelectionRange(element.value.length, element.value.length);
    this.lastFocusRow$.next(index);

  }

  // onRowBlur(index) {

  // }

  public imagesChanged(row: FormGroup, changedData: { partLine: string; partNumber: string }) {
    if (changedData) {
      const rows = this.formRows();
      for (let i = 0; i < rows.length; i++) {
        const currentRow = rows.at(i).value as any;
        if (currentRow.partLine === changedData.partLine &&
          currentRow.partNumber === changedData.partNumber) {
          currentRow.partImages$.value.push(changedData);
          DepotValidators.persistPartImages(rows.at(i));
          break;
        }
      }
      this.formRows().at(this.lastFocusRow$.value);
    }
    DepotValidators.persistPartImages(row);
  }

  private formRows() {
    return this.form.get('rows') as UntypedFormArray;
  }
}
