<div class="content mat-elevation-z8">
  <!--  -->
  <mat-expansion-panel #searchPanel>
    <mat-expansion-panel-header>
      <mat-panel-title class="filter-panel-title">
        Filters
        <button mat-button
                color="primary"
                (click)="loadData($event)">Search</button>
        <button mat-button
                color="primary"
                (click)="resetFilters($event)">Reset</button>

      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-container matExpansionPanelContent>
        @let cache = cachedFilters$|async;

      <mat-form-field>
        <mat-label>Start Date</mat-label>
        <input matInput
               [ngModel]="cache.startDate | date:'yyyy-MM-dd'"
               (ngModelChange)="cache.startDate = $event"
               [matDatepicker]="startDate">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix
                               [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>

      </mat-form-field>

      <mat-form-field>
        <mat-label>End Date</mat-label>
        <input matInput
               [(ngModel)]="cache.endDate"
               [matDatepicker]="endDate">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix
                               [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Username</mat-label>
        <input matInput
               type="text"
               [(ngModel)]="cache.userName">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Has Downloaded</mat-label>
        <mat-select [(ngModel)]="cache.hasDownloaded">
          <mat-option value="">- Any -</mat-option>
          <mat-option [value]="true">
            Yes
          </mat-option>
          <mat-option [value]="false">
            No
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Location</mat-label>
        <mat-select [(ngModel)]="cache.location">
          <mat-option value=""> - Any -</mat-option>
          <mat-option value="WI">Wisconsin</mat-option>
          <mat-option value="TN">Tennessee</mat-option>
          <mat-option value="CO">Colorado</mat-option>
        </mat-select>
      </mat-form-field>

    </ng-container>
  </mat-expansion-panel>
  <mat-table [dataSource]="dataSource"
             matSort
             [matSortActive]="(cachedFilters$ | async).orderBy?.split(' ')[0]"
             [matSortDirection]="(cachedFilters$ | async).orderBy?.split(' ')[1]"
             (matSortChange)="sortFilters()">

    <ng-container matColumnDef="edit">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef></mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">
        <a mat-icon-button
           matTooltip="Edit"
           [routerLink]="[row.id]">
          <span class="fa fa-edit"></span>
        </a>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="icons">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef>Downloaded</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">
        <span *ngIf="row.verifiedDate"
              [matTooltip]="'Downloaded on ' + (row.verifiedDate | utcdate:'short')"
              class="fa fa-download"></span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="dealerNumber">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>Dealer</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.dealerNumber}}</mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="partLine">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>Line</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.partLine}}</mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="createdBy">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>Created By</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.createdBy}}</mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="createdDate">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>Created Date</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.createdDate | utcdate:'short'}}</mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="partCount">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef>Parts</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.partCount}}</mat-cell>
      <mat-footer-cell *matFooterCellDef>{{dataSource.countOfParts$|async}}</mat-footer-cell>

    </ng-container>

    <ng-container matColumnDef="imageCount">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef>Images</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.imageCount}}</mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="dataSource.columns; sticky: true"
                    class="table-header-primary"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataSource.columns;"></mat-row>

    <mat-footer-row *matFooterRowDef="dataSource.columns"
                    class="tw-border-t-4 tw-border-t-black tw-border-double tw-font-extrabold">
    </mat-footer-row>

    <div class="no-data-row"
         *matNoDataRow>
      No putaways found
    </div>

  </mat-table>

  <mat-paginator [disabled]="dataSource?.isLoading$ | async"
                 (page)="loadData()"
                 [pageSize]="(cachedFilters$ | async)?.takeCount"
                 [pageSizeOptions]="[25, 50, 250, 500]">
  </mat-paginator>
</div>

<div class="dp-top-fab-container">

  <a mat-mini-fab
     [routerLink]="['new']"
     matTooltip="Create New Putaway"
     matTooltipPosition="below"
     color="accent">
    <span class="fa fa-plus"></span>
  </a>
</div>
