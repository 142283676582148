import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FormChangesGuard, RoleGuard } from '@depot/@common';
import { DepotCommonModule } from '@depot/@common/depot-common.module';
import { DepotComponentsModule } from '@depot/@components/depot-components.module';
import {
  PutawayEditComponent, PutawayEditRowComponent, PutawayGridComponent,
  PutawayHelpComponent, PutawayScrapDialogComponent
} from '@depot/putaway';

@NgModule({
  imports: [
    // VirtualScrollerModule,
    CommonModule,
    DepotCommonModule,
    DepotComponentsModule,
    RouterModule.forChild([{
      path: '',
      component: PutawayGridComponent,
      canActivate: [RoleGuard],
      data: {
        isNav: false,
        text: 'Put Away',
        icon: 'fa fa-dolly-flatbed',
        roles: [RoleGuard.Admin]
      }
    },
    {
      path: ':id',
      component: PutawayEditComponent,
      canActivate: [RoleGuard],
      canDeactivate: [FormChangesGuard],
      data: {
        isNav: false,
        title: 'Put Away > Edit',
        icon: 'fa fa-dolly-flatbed',
        roles: [RoleGuard.Admin]
      }
    }])
  ],
  declarations: [
    PutawayEditComponent,
    PutawayEditRowComponent,
    PutawayGridComponent,
    PutawayHelpComponent,
    PutawayScrapDialogComponent,
  ],
  exports: [
    PutawayEditComponent,
    PutawayEditRowComponent,
    PutawayGridComponent,
    PutawayHelpComponent,
    PutawayScrapDialogComponent,
  ]
})
export class PutawayModule { }
