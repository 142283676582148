import { DataSource } from '@angular/cdk/table';

import { IPutAwaySearch, PutawayRepositoryService } from '@depot/@data';
import { IPagedResults, IPutawayAggregate } from '@depot/custom';

import { BehaviorSubject, finalize, Subject } from 'rxjs';

export class PutAwayGridDataSource extends DataSource<IPutawayAggregate> {
  public isLoading$ = new BehaviorSubject(false);
  public totalRows$ = new BehaviorSubject(0);
  public countOfParts$ = new Subject();
  public get filterName() { return 'putaway_grid'; }
  public columns = [
    'edit',
    'icons',
    // 'verifiedDate',
    'dealerNumber',
    'partLine',
    'createdBy',
    'createdDate',
    'partCount',
    'imageCount',
  ];
  private data$ = new Subject<IPutawayAggregate[]>();
  constructor(private putawayRepo: PutawayRepositoryService) {
    super();

  }
  connect() {
    return this.data$;
  }

  public loadData(filters: IPutAwaySearch) {

    this.isLoading$.next(true);
    this.putawayRepo.searchPutAway(filters)
      .pipe(
        // catchError(err => of({ items: [] })),
        finalize(() => this.isLoading$.next(false))
      ).subscribe((x: IPagedResults<IPutawayAggregate>) => {
        this.data$.next(x.items);
        this.totalRows$.next(x.totalCount);
        this.countOfParts$.next(x.items.map((putaway: any) => putaway.partCount).reduce((a, b) => a + b, 0));
      });
  }

  disconnect(): void {
    this.data$.complete();
    this.isLoading$.next(false);
    this.isLoading$.complete();
    this.totalRows$.complete();
    // this.depotContext.close();
  }

}
