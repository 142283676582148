import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, Inject, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AuthService, DepotValidators, HelperService, RoleGuard } from '@depot/@common';
import { DealerReturnRepositoryService } from '@depot/@data';
import { IClientError, IDealerReturnScrap, IDealerReturnScrapOptions } from '@depot/custom';

import { BehaviorSubject, catchError, of, switchMap, throwError } from 'rxjs';

@Component({
  selector: 'depot-putaway-scrap-dialog',
  templateUrl: './putaway-scrap-dialog.component.html',
  styles: []
})
export class PutawayScrapDialogComponent implements OnInit {

  public globalErrors$ = new BehaviorSubject<string[]>([]);
  // public openWeightTickets$ = new BehaviorSubject<IDealerReturnWeightTicket[]>([]);
  public form: FormGroup;
  public isReadOnly$ = new BehaviorSubject(true);
  public scrapRow$ = new BehaviorSubject<IDealerReturnScrap>(null);
  public scrapCommentOptions$ = new BehaviorSubject<IDealerReturnScrapOptions>(null);
  public legacyRegularComment$ = signal('');
  public legacyAuditComment$ = signal('');
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: {
      dealerNumber: string;
      putAwayReportId: number;
      dealerReturnSize: 'large' | 'small';
      dealerReturnWeightTicketId: number;
    },
    public dialogRef: MatDialogRef<PutawayScrapDialogComponent>,
    private dealerReturnService: DealerReturnRepositoryService,
    private fb: FormBuilder,
    private helper: HelperService,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {

    this.dealerReturnService.getScrapCommentOptions()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === HttpStatusCode.NotFound) {
            return of(null);
          }
          return throwError(() => err);
        }),
        switchMap(commentOptions => {
          this.scrapCommentOptions$.next(commentOptions);
          return this.dealerReturnService.getDealerReturnScrapByPutAwayReportId(this.dialogData.putAwayReportId);
        })
      ).subscribe(scrapRow => {
        const regularComments = scrapRow?.regularComments?.split('.').map(x => x.trim()) ?? [];
        const auditComments = scrapRow?.auditComments?.split('.').map(x => x.trim()) ?? [];
        const commentOptions = this.scrapCommentOptions$.value;
        if (scrapRow?.regularComments?.length > 0 && !commentOptions.regularCommentOptions.includes(regularComments[0])) {
          this.legacyRegularComment$.set('Non-standard option: "' + scrapRow?.regularComments + '" This value will be overwritten on save!');
        }
        if (scrapRow?.auditComments?.length > 0 && !commentOptions.auditCommentOptions.includes(auditComments[0])) {
          this.legacyAuditComment$.set('Non-standard option: "' + scrapRow?.auditComments + '" This value will be overwritten on save!');

        }

        this.form = this.fb.group({
          id: scrapRow?.id,
          regularIbs: this.fb.control(scrapRow?.regularIbs, [Validators.min(0)]),
          regularComments: [regularComments],
          auditIbs: this.fb.control(scrapRow?.auditIbs, [Validators.min(0)]),
          auditComments: [auditComments],
        }, {
          updateOn: 'change',
          validators: [
            DepotValidators.RequiredWhenSetNumeric('regularIbs', 'regularComments'),
            DepotValidators.RequiredWhenSetNumeric('auditIbs', 'auditComments'),
          ]
        });
        this.scrapRow$.next(scrapRow);

        const canChange = this.auth.isInRole(RoleGuard.ScrapAdmin) || this.auth.user.userName === scrapRow?.createdBy;

        this.isReadOnly$.next(!canChange);

        this.form.markAsPristine();
      });

  }

  public save() {
    const formData = this.form.getRawValue();
    this.globalErrors$.next([]);
    if (this.form.valid === false) {
      this.form.markAllAsTouched();
      this.form.markAsDirty();
      return;
    }
    const scrapRow = this.scrapRow$.getValue();
    const original = <IDealerReturnScrap>{

      id: scrapRow.id,
      regularIbs: scrapRow.regularIbs,
      regularComments: scrapRow.regularComments,
      auditIbs: scrapRow.auditIbs,
      auditComments: scrapRow.auditComments,
    };

    formData.regularComments = formData.regularComments?.join('. ');
    formData.auditComments = formData.auditComments?.join('. ');

    this.dealerReturnService.saveDealerReturnScrap(formData, original)
      .pipe(
        catchError((error: IClientError) => {
          this.helper.logger.error('Error saving scrap report id', error);
          this.helper.mapErrors(error, null, this.form, this.globalErrors$);

          return of();
        })
      )
      .subscribe((x: IDealerReturnScrap) => {
        if (x) {
          // this.scrapRow$.next(x);
          // this.form.patchValue(x);

          this.helper.showMessage('Changes saved successful', 'success');

          // this.form.markAsPristine();
          this.dialogRef.close(x);
        }
      });

  }

  public async closeDialog() {
    // if (this.form.dirty) {
    //   const shouldClose = await this.helper.confirmDialog('You have pending changes, are you sure you want to close?');
    //   if (shouldClose) {
    //     this.dialogRef.close(null);
    //   }
    // } else {
    //   this.dialogRef.close(null);

    // }
  }



}
