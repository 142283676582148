<div class="tw-flex tw-flex-row tw-flex-wrap"
     [formGroup]="row">
  <mat-form-field>
    <mat-label>Part Number</mat-label>
    <input (change)="partChange.next({row: row, index: i})"
           (focus)="lastFocusRow$.next(i)"
           matInput
           formControlName="partNumber"
           [required]="i===0 || form.controls['rows'].controls.length - 1 !== i"
           type="text"
           maxlength="15"
           depotUppercase />
    <mat-error [depot-form-error]="row"
               errorControlName="partNumber">
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Description</mat-label>
    <input matInput
           formControlName="description"
           [required]="i===0 || form.controls['rows'].controls.length - 1 !== i"
           type="text"
           maxlength="15"
           depotUppercase />
    <mat-error [depot-form-error]="row"
               errorControlName="description">
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Quantity</mat-label>
    <input matInput
           formControlName="quantity"
           [required]="i===0 || form.controls['rows'].controls.length - 1 !== i"
           type="number"
           max="2000"
           min="1" />
    <mat-error [depot-form-error]="row"
               errorControlName="quantity">
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Location</mat-label>
    <input matInput
           (focus)="onRowFocus($event, i)"
           (blur)="rowBlur.next(i)"
           formControlName="partLocation"
           [required]="i===0 || form.controls['rows'].controls.length - 1 !== i"
           type="text"
           maxlength="8"
           depotUppercase />
    <mat-error [depot-form-error]="row"
               errorControlName="partLocation">
    </mat-error>
  </mat-form-field>
  <button (click)="removeFormItem.next({index: i, key: row.get('rowKey').value})"
          mat-icon-button
          matTooltip="Remove Item"
          [tabIndex]="-1">
    <span class="fa fa-trash-alt"></span>
  </button>
  <div>
    @if(row.controls['part$'].value | async; as part){
    {{part.internalDealerNetPrice | currency}} |
    WI:<span class="tw-font-extrabold">{{ part.inventoryWi}}</span> |
    TN:<span class="tw-font-extrabold">{{ part.inventoryTn }}</span> |
    CO:<span class="tw-font-extrabold">{{ part.inventoryCo}}</span> |
    Code: {{part.priceBookCode}}
    }@else {
    @if(row.controls['priceBookLine$'].value | async; as priceBook){
    {{priceBook.dealerPrice | currency}} | {{priceBook.description}} | {{priceBook.year}}

    }@else {
    <span class="mat-error"
          style="display: unset;">
      No part info available
    </span>
    }
    }
  </div>
</div>
<div style="color: darkgoldenrod;"
     *ngFor="let warn of row.warnings | keyvalue">
  {{warn.value}}
</div>
<depot-image-row [ImageArray]="row.controls['partImages$'].value"
                 (ImagesChanged)="imagesChanged(row, $event)"
                 ParentIdKey='putAwayRowId'
                 [ParentId]="row.value.id" />
